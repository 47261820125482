import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "./Widget";
import { dateToString } from "../../../Helper/DateFunctions";
import { EvjuSpaceEvent, EvjuSpaceEventSlim, getEvents } from "../../../connectors/EventConnector";
import { useEffect, useState } from "react";
import { EvjuSpaceGroup } from "../../../connectors/GroupConnector";

export interface GroupEventWidgetParameter {
    group: EvjuSpaceGroup,
    events: Array<EvjuSpaceEvent>,
    timeframe: "future"|"past",
}

function GroupEventWidget(props : GroupEventWidgetParameter) {

    
    const [group, setGroup] = useState<EvjuSpaceGroup>(props.group)
    const [events, setEvents] = useState<EvjuSpaceEvent[]>(props.events)

    useEffect(() => {
        if(props.events) {
            const allEvents:Array<EvjuSpaceEvent> = events
            setEvents([])
            let result:Array<EvjuSpaceEvent> = [];

            const sortbyStarttimeAsc = (a:EvjuSpaceEvent, b:EvjuSpaceEvent) => {
                if(new Date(a.starttime) > new Date(b.starttime))
                    return 1
                return -1
            }

            const sortbyStarttimeDsc = (a:EvjuSpaceEvent, b:EvjuSpaceEvent) => {
                if(new Date(a.starttime) < new Date(b.starttime))
                    return 1
                return -1
            }

            if(props.timeframe === "future") {
                result = allEvents.filter((e:EvjuSpaceEvent) => {
                    return new Date(e.endtime) > new Date()
                })
                result.sort(sortbyStarttimeAsc)
            } else {
                result = allEvents.filter((e:EvjuSpaceEvent) => {
                    return new Date(e.endtime) < new Date()
                })
                result.sort(sortbyStarttimeDsc)
            }

            setEvents(result)
        }
    }, [props.events])

    return (
        <Widget headerBox={(props.timeframe === "future" ? "Zukünftige Termine" : "Vergangene Termine")} add={(group.isAdmin && props.timeframe == "future") ? ("/group/" + group._id + "/createEvent") : ""} standardHeight={true}>
            { Array.isArray(events) ? events.map((event:any, j:any) => {
                        return (
                            <div key={"event" + j}>
                                <a href={"/group/" + group._id + "/event/" + event._id}>
                                    { event.name + " (" + dateToString(event.starttime) + ")" } 
                                </a>
                            </div>
                        );
                    }
                ) : ""
            }
        </Widget>
    );

}
export default GroupEventWidget;