import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import { editApplication, getServer, isUserServerAdmin } from "../../connectors/ServerConnector";
import Card from "../Components/Card";
import WidgetArea from "../Components/Widgets/WidgetArea";
import Widget from "../Components/Widgets/Widget";
import { useContext, useDebugValue, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { EvjuSpaceUser, getUser } from "../../connectors/UserConnector";
import draftToHtml from "draftjs-to-html";

function Server() {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    const [applicants, setApplicants] = useState<Array<EvjuSpaceUser>>([])
    const server : any = useLoaderData()

    useEffect(() => {
        
        const fetchData = async () => {
            if(server.applicants) {
                let newApplicants: Array<EvjuSpaceUser> = []

                for(let applicant of server.applicants) {
                    newApplicants.push(await getUser(applicant.id))
                }
                setApplicants(newApplicants)
            }
        }

        if(server.applicants && server.applicants.length > 0) {
            fetchData()
        }
    }, [server.applicants])

    function clickEditApplication(userid: string, confirm: boolean) {
        editApplication(server._id, userid, confirm)

        let newApplicants: Array<EvjuSpaceUser> = applicants.filter((applicant) => applicant._id !== userid)
        console.log(newApplicants)
        setApplicants(newApplicants)
    }

    return (
        <Card heading={server? server.name : "Loading..."} settings={server.isAdmin ? ("/server/" + server._id + "/settings/") : undefined} >
            <WidgetArea columns="2">
                <Widget headerBox="Herzlich Willkommen" standardHeight={true}>
                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(server.description))}} ></div>
                </Widget>

                {server.isAdmin && applicants.length > 0 ? 
                    <Widget headerBox="Mitgliedsanträge" standardHeight={true}>
                        <table className="table">
                            <thead className="table-head">
                                <tr>
                                    <th className="table-cell">Name</th>
                                    <th className="table-cell">Antrag genehmigen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applicants.map((applicant: any, i: number) => {
                                    const row = i % 2 == 0 ? "table-row-odd" : "table-row-even"
                                    return <>
                                            <tr>
                                                <td className={"table-cell " + row}>{applicant.firstName} {applicant.lastName}</td>
                                                <td className={"table-cell " + row}>
                                                    <a className="ri-checkbox-circle-line" onClick={() => {clickEditApplication(applicant._id, true)}}></a>
                                                    <a className="ri-delete-bin-line" onClick={() => {clickEditApplication(applicant._id, false)}}></a>
                                                </td>
                                            </tr>
                                        </>
                                })}
                            </tbody>
                        </table>
                    </Widget>
                : ""}

                <Widget headerBox="Das ist erst der Anfang!" standardHeight={true}>
                    <p>Wir haben noch unendlich viele Ideen, was hier alles möglich sein soll.</p>
                    <p>Leider braucht die Programmierung etwas Zeit. Wir bitten deshalb um Verständnis, wenn am Anfang noch nicht alles perfekt funktioniert.</p>
                </Widget>
                
            </WidgetArea>
        </Card>
    );
}
export default Server;

export async function serverLoader({ params } : any) {
    if(params.id != 0) {
        const server = await getServer(params.id)
        return server;
    }

    return [{_id: params.id, name: "Loading...", groups: [] }]
}