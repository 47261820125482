import { useLoaderData } from "react-router-dom";
import { EvJuSpaceLegalDoc, getTermsOfUse } from "../../connectors/LegalConnector";
import Card from "../Components/Card";
import draftToHtml from "draftjs-to-html";
import Footer from "./Footer";

function TermsOfUse() {
    
    const doc: any = useLoaderData();

    return (
        <>
            <Card heading={"Nutzungsbedingungen"} back="javascript:history.back()" visible_when_loggedout={true} >    
                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(doc.text))}} ></div>
            </Card>
            <Footer />
        </>
    );
}
export default TermsOfUse;

export async function termsOfUseLoader({ params } : any) {
    const doc:EvJuSpaceLegalDoc = await getTermsOfUse()
    return doc;
}