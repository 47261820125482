import { useEffect, useState } from "react";
import { getUser, EvjuSpaceUser, getUserList } from "../../connectors/UserConnector";
import EvjuSpaceUserSelector from "./FormElements/EvjuSpaceUserSelector";
import { EvjuSpaceServer, EvjuSpaceServerMember, addMemberToServer, getServer, removeMemberfromServer } from "../../connectors/ServerConnector";
import { EvjuSpaceGroup, addMemberToGroup, getGroup, removeMemberFromGroup } from "../../connectors/GroupConnector";
import EvjuSpaceButton from "./FormElements/EvjuSpaceButton";

export interface EvjuSpaceMemberListParameter {
    server: EvjuSpaceServer,
    group?: EvjuSpaceGroup,
    allowAdding?: boolean,
}

function EvjuSpaceMemberList(props: EvjuSpaceMemberListParameter) {

    const [server, setServer] = useState<EvjuSpaceServer>(props.server)
    const [group, setGroup] = useState<EvjuSpaceGroup>()

    const [admins, setAdmins] = useState<Array<EvjuSpaceUser>>()
    const [memberSetDetails, setMemberSetDetails] = useState<Array<EvjuSpaceUser>>()
    const [userSet, setUserSet] = useState<Array<string>>()
    const [newMembers, setNewMembers] = useState<Array<string>>()

    useEffect(() => {
        if(!props.group) {
            const fetchData = async () => {
                const userList: Array<EvjuSpaceUser> = await getUserList()
                let newUserSet: Array<string> = []
                for(const user of userList) {
                    newUserSet.push(user._id)
                }

                let newMemberSetDetails: Array<EvjuSpaceUser> = []
                let newMemberSet: Array<string> = []
                if(server.members) {
                    for( const member of server.members) {
                        const newUser = await getUser(member.id)
                        newMemberSetDetails.push(newUser)

                        newMemberSet.push(member.id)
                    }
                }

                setMemberSetDetails(newMemberSetDetails)
                setUserSet(newUserSet.filter(u => !newMemberSet.includes(u)))
            }

            fetchData()
        }
    }, [server])

    useEffect(() => {
        if(props.group) {
            setGroup(props.group)

            const fetchData = async () => {
                if(server.members) {
                    const userList: Array<EvjuSpaceServerMember> = server.members
                    let newUserSet: Array<string> = []
                    for(const user of userList) {
                        newUserSet.push(user.id)
                    }

                    let newMemberSetDetails: Array<EvjuSpaceUser> = []
                    let newMemberSet: Array<string> = []
                    if(group?.members) {
                        for( const member of group.members) {
                            const newUser = await getUser(member.id)
                            newMemberSetDetails.push(newUser)

                            newMemberSet.push(member.id)
                        }
                    }

                    setMemberSetDetails(newMemberSetDetails)
                    setUserSet(newUserSet.filter(u => !newMemberSet.includes(u)))
                }
            }

            fetchData()
        }
    }, [group])

    async function saveNewMember() {
        if(newMembers && newMembers.length > 0) {
            if(group) {
                const response = await addMemberToGroup(newMembers[0], group._id)
                setGroup(await getGroup(group._id))
            } else {
                const response = await addMemberToServer(newMembers[0], server._id)
                setServer(await getServer(server._id))
            }
            setNewMembers([])
        } else {
            console.error("No valid User selected")
        }

    }

    async function removeMember(uid: string) {
        if(group) {
           const response = await removeMemberFromGroup(uid, group._id) 
           setGroup(await getGroup(group._id))
        } else {
            const response = await removeMemberfromServer(uid, server._id)
            setServer(await getServer(server._id))
        }
    }


    return <>
        <table style={{border: '2px solid'}}>
            <thead>
                <tr style={{borderBottom: '2px solid', fontWeight: 650}}>
                    <td>Name</td>
                    <td>Admin</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {!memberSetDetails ? <tr><td>Keine Daten</td></tr> : memberSetDetails.map((member) => {
                    return(
                        <tr style={{border: '1px solid'}} key={"member" + member._id}>
                            <td>{member.firstName + " " + member.lastName}</td>
                            <td>ToDo</td>
                            <td>
                                <a className="list-icon ri-delete-bin-6-line" href="#" title="Mitglied entfernen" onClick={() => {removeMember(member._id)}}></a>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>

        {(props.allowAdding && userSet && userSet.length > 0) ? (
            <div>
                <EvjuSpaceUserSelector label="Mitglieder hinzufügen" value={newMembers} onChange={setNewMembers} set={userSet} />
                <EvjuSpaceButton text="Hinuzufügen" onClick={saveNewMember} />
            </div>
        ) : ""}
    </>
}
export default EvjuSpaceMemberList;