import { useEffect, useState } from "react";
import 'react-select-search/style.css'
import { EvjuSpaceUser, getUser, getUserList } from "../../../connectors/UserConnector";
import EvjuSpaceInput from "./EvjuSpaceInput";
import EvjuSpaceOverlay from "../Overlay";

export interface EvjuSpaceUserSelectorParameter {
    label?: string,
    onChange?: any,
    onSave?: any,
    set?: Array<string>,
    value?: Array<string>,
    multiselect?: boolean,
    error?: boolean,
}

function EvjuSpaceUserSelector(props: EvjuSpaceUserSelectorParameter) {

    const [inputValue, setInputValue] = useState<string>("") //!
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>("")
    const [userSet, setUserSet] = useState<Array<{uid: string, name: string}>>()
    const [searchedSet, setSearchedSet] = useState<Array<{uid: string, name: string}>>() //!
    const [selectedUsers, setSelectedUsers] = useState<Array<string>>(props.value? props.value : [])

    useEffect(() => {
        const fetchData = async () => {
            const loadedUserSet = await loadUserSet(props.set)
            setUserSet(loadedUserSet)
            setSearchedSet(loadedUserSet)
        }
        fetchData()
    }, [props.set])

    useEffect(() => {
        setSelectedUsers(props.value? props.value : [])
    }, [props.value])

    useEffect(()=>{
        const names = getSelectedNames(selectedUsers);
            setInputValue(names)
    }, [selectedUsers, userSet])

    useEffect(() => {
        setSearchedSet(userSet?.filter(element => element.name.toLowerCase().includes(searchValue.toLowerCase())))
    }, [searchValue])
    
    function onFocus(e: any) {
        setIsOverlayOpen(true)
    }

    function isSelected(uid: string): boolean {
        return selectedUsers.includes(uid)
    }

    function toggleSelection(uid: string) {
        let newSelectedUsers = [];
        if(selectedUsers.includes(uid)) {
            newSelectedUsers = selectedUsers.filter(element => element !== uid)
        } else {
            if(props.multiselect) {
                newSelectedUsers = [...selectedUsers, uid]
            } else {
                newSelectedUsers = [uid]
            }
        }

        setSelectedUsers(newSelectedUsers)
        props.onChange(newSelectedUsers)
    }

    function getSelectedNames(newSelectedUsers: Array<string>) : string {
        let result = ""
        if(userSet) {
            userSet.forEach(userInSet => {
                newSelectedUsers.forEach(selected => {
                    if(selected === userInSet.uid) {
                        if(result !== "") {
                            result += "; "
                        }
                        result += userInSet.name
                    }
                })
            })
        }
        return result
    }

    async function loadUserSet(set: Array<string>|undefined) {
        let newUserSet: Array<{uid: string, name: string}> = []
        if(set && set.length > 0) {
            newUserSet = []
                for(const userid of set) {
                    const user = await getUser(userid)
                    newUserSet.push({uid: user._id, name: (user.firstName ? user.firstName : "NaN") + " " + (user.lastName ? user.lastName : "NaN")})
                }
        } else {
            const userList: Array<EvjuSpaceUser> = await getUserList()
            newUserSet = []
            for(const user of userList) {
                newUserSet.push({uid: user._id, name: user.firstName + " " + user.lastName})
            }
        }
        return newUserSet
    }

    return (<>
        <EvjuSpaceInput value={inputValue} onChange={setInputValue} label={props.label} onFocus={onFocus} error={props.error} />

        <EvjuSpaceOverlay isOpen={isOverlayOpen} onClose={() => {setIsOverlayOpen(false)}}>
            <h2>Benutzer auswählen</h2>
            <EvjuSpaceInput value={searchValue} onChange={setSearchValue} label="Suchen" />
            <div className="evjuspaceuserselector-scroll">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Auswahl</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchedSet?.map((user, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{user.name}</td>
                                        <td>{isSelected(user.uid) ?
                                            (<a className="toggle-button ri-toggle-fill" title="Anwesenheit einschalten" onClick={() => {toggleSelection(user.uid)}}></a>) :
                                            (<a className="toggle-button ri-toggle-line" title="Anwesenheit ausschalten" onClick={() => {toggleSelection(user.uid)}}></a>)}
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </EvjuSpaceOverlay>
    </>);
}
export default EvjuSpaceUserSelector;