import "./../style.css";
import 'remixicon/fonts/remixicon.css'

export interface CardParameter {
    heading: string,
    children: any, //HTML Elements
    addFn?: any, //function
    back?: string,
    headerBox?: string,
    settings?: string,
    visible_when_loggedout?: boolean,
}

function Card(props : CardParameter) {

    return (
        <div className={"content-container" + (props.visible_when_loggedout ? " remove-margin" : "")}>
            <div className="card-heading">
                <h1>{props.heading}</h1>
            </div>
            <div className="card-container">
                <div className="card-header">
                    <div className="card-backbtn">
                    {props.back ? <a className="card-icon ri-arrow-left-line" title="Zurück" href={props.back}></a> : ""}
                    </div>
                    <div className="card-title">
                        {props.headerBox}
                    </div>
                    <div className="card-settings">
                        {props.settings ? <a className="card-icon ri-settings-4-line" href={props.settings}></a> : ""}
                        {props.addFn ? <a className="widget-icon ri-add-line" title="Hinzufügen" onClick={props.addFn}></a> : ""}
                    </div>
                </div>
                <div className="card-content">
                    {props.children}
                </div>
            </div>
        </div>);

}
export default Card;