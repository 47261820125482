import { useEffect, useState } from "react";
import { newGroup } from "../../../connectors/GroupConnector";
import EvjuSpaceUserSelector from "../../Components/FormElements/EvjuSpaceUserSelector";
import EvjuSpaceButton from "../../Components/FormElements/EvjuSpaceButton";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";


function NewGroup(props:any) {

    const [groupAdmin, setGroupAdmin] = useState<Array<string>>([])
    const [groupName, setGroupName] = useState<string>("")
    const [formerror, setFormerror] = useState<string>("")
    const [userSet, setUserSet] = useState<Array<string>>([])

    useEffect(() => {
        let newUserSet: Array<string> = []
        for(const member of props.server.members) {
            newUserSet.push(member.id)
        }
        setUserSet(newUserSet)
    }, [])

    async function btnHandler() {
        if(groupAdmin.length <= 0 && groupName === "") {
            setFormerror("Die Felder Gruppenname und Gruppenadmin dürfen nicht leer sein.")
        } else if(groupAdmin.length <= 0) {
            setFormerror("Das Feld Gruppenadmin darf nicht leer sein.")
        } else if(groupName === "") {
            setFormerror("Das Feld Gruppenname darf nicht leer sein.")
        } else {
            const response = await newGroup(groupName, groupAdmin[0], props.server._id)
            if(response.groupid) {
                window.location.href = '/group/' + response.groupid
            }
        }
    }

    return <>
        <h3>Neue Gruppe erstellen</h3>
        <div className="formerror">{formerror}</div>
        <EvjuSpaceInput label="Gruppenname" value={groupName} onChange={setGroupName}/>
        <EvjuSpaceUserSelector label="Gruppenadmin" value={groupAdmin} onChange={setGroupAdmin} set={userSet}/>
        <EvjuSpaceButton text="Gruppe erstellen" onClick={btnHandler}/>
    </>
}
export default NewGroup;