import { NavLink, useLoaderData } from "react-router-dom";
import Card from "../../Components/Card";
import EvjuSpaceButton from "../../Components/FormElements/EvjuSpaceButton";
import { EvJuSpaceMailSetting, getEmailSettings, sendWelcomeMail, setEmailSetting } from "../../../connectors/EmailConnector";
import { useEffect, useState } from "react";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceEditor from "../../Components/FormElements/EvjuSpaceEditor";
import IndexCard from "../../Components/IndexCard";
import { EvJuSpaceLegalDoc, getImpressum, getPrivacy, getTermsOfUse, saveLegalDoc } from "../../../connectors/LegalConnector";
import EvjuSpaceOverlay from "../../Components/Overlay";
import { forceLogoutOfAllUsers } from "../../../connectors/UserConnector";

function Adminpanel() {

    const [ emailSettings, legalDocs ] : any = useLoaderData();

    const [mailSmtpServer, setMailSmtpServer] = useState<string>(emailSettings.smtpServer)
    const [mailSmtpPort, setMailSmtpPort] = useState<string>(emailSettings.smtpPort)
    const [mailSmtpUser, setMailSmtpUser] = useState<string>(emailSettings.smtpUser)
    const [mailSmtpPassword, setMailSmtpPassword] = useState<string>(emailSettings.smtpPassword)
    const [mailFromAdress, setMailFromAdress] = useState<string>(emailSettings.fromAdress)
    const [mailGreeting, setMailGreeting] = useState<string>(emailSettings.greeting)
    const [mailFarewells, setMailFarewells] = useState<string>(emailSettings.farewells)
    const [mailWelcomeText, setMailWelcomeText] = useState<string>(emailSettings.welcomeText)
    const [mailEmailConfirmationText, setMailEmailConfirmationText] = useState<string>(emailSettings.emailConfirmationText)
    const [mailForgotPasswordText, setMailForgotPasswordText] = useState<string>(emailSettings.forgotPasswordText)

    const [impressum, setImpressum] = useState<string>(legalDocs.impressum.text)
    const [termsOfUse, setTermsOfUse] = useState<string>(legalDocs.termsOfUse.text)
    const [privacy, setPrivacy] = useState<string>(legalDocs.privacy.text)
    const [isForcedLogoutOverlayOpen, setIsForcedLogoutOverlayOpen] = useState<boolean>(false)

    useEffect(() => {
        if(mailSmtpServer) {
            setEmailSetting("smtpServer", mailSmtpServer)
        }
    }, [mailSmtpServer])

    useEffect(() => {
        if(mailSmtpPort) {
            setEmailSetting("smtpPort", mailSmtpPort)
        }
    }, [mailSmtpPort])

    useEffect(() => {
        if(mailSmtpUser) {
            setEmailSetting("smtpUser", mailSmtpUser)
        }
    }, [mailSmtpUser])

    useEffect(() => {
        if(mailSmtpPassword) {
        setEmailSetting("smtpPassword", mailSmtpPassword)
    }
    }, [mailSmtpPassword])

    useEffect(() => {
        if(mailFromAdress) {
            setEmailSetting("fromAdress", mailFromAdress)
        }
    }, [mailFromAdress])

    useEffect(() => {
        if(mailGreeting) {
        setEmailSetting("greeting", mailGreeting)
    }
    }, [mailGreeting])

    useEffect(() => {
        if(mailFarewells) {
        setEmailSetting("farewells", mailFarewells)
    }
    }, [mailFarewells])

    useEffect(() => {
        if(mailWelcomeText) {
        setEmailSetting("welcomeText", mailWelcomeText)
    }
    }, [mailWelcomeText])

    useEffect(() => {
        if(mailEmailConfirmationText) {
        setEmailSetting("emailConfirmationText", mailEmailConfirmationText)
    }
    }, [mailEmailConfirmationText])

    useEffect(() => {
        if(mailForgotPasswordText) {
        setEmailSetting("forgotPasswordText", mailForgotPasswordText)
    }
    }, [mailForgotPasswordText])

    function saveImpressum() {
        saveLegalDoc({
            title: "Impressum",
            text: impressum,
        })
    }

    function saveTermsOfUse() {
        saveLegalDoc({
            title: "TermsOfUse",
            text: termsOfUse,
        })
    }

    function savePrivacy() {
        saveLegalDoc({
            title: "Privacy",
            text: privacy,
        })
    }

    function forcedLogout() {
        forceLogoutOfAllUsers()

        alert("Alle User werden ausgeloggt, wenn sie das nächste Mal versuchen ihren refreshToken zu verlängern. Das kann einige Minuten dauern.")

        setIsForcedLogoutOverlayOpen(false)
    }

    return (
        <>
            <Card heading="Einstellungen" headerBox="Administration">
                <IndexCard headerBox="Servereinstellungen">
                    <p>
                        <NavLink to="/settings/admin/newserver">Neuen Server erstellen</NavLink>
                    </p>
                </IndexCard>                

                <IndexCard headerBox="Emaileinstellungen">
                    <EvjuSpaceButton text="SET" onClick={() => {sendWelcomeMail("smtpUser")}} />

                    <EvjuSpaceInput label="SMTP Server" value={mailSmtpServer} onChange={setMailSmtpServer} />

                    <EvjuSpaceInput label="SMTP Port" value={mailSmtpPort} onChange={setMailSmtpPort} />

                    <EvjuSpaceInput label="SMTP User" value={mailSmtpUser} onChange={setMailSmtpUser} />

                    <EvjuSpaceInput label="SMTP Passwort" type="password" value={mailSmtpPassword} onChange={setMailSmtpPassword} />

                    <EvjuSpaceInput label="Absenderadresse" value={mailFromAdress} onChange={setMailFromAdress} />

                    <EvjuSpaceEditor label="Begrüßung in Emails" rawState={mailGreeting} onChangeExportRawState={(state: any) => {setMailGreeting(JSON.stringify(state))}} />

                    <EvjuSpaceEditor label="Verabschiedung in Emails" rawState={mailFarewells} onChangeExportRawState={(state: any) => {setMailFarewells(JSON.stringify(state))}} />

                    <EvjuSpaceEditor label="Willkommensmail" rawState={mailWelcomeText} onChangeExportRawState={(state: any) => {setMailWelcomeText(JSON.stringify(state))}} />

                    <EvjuSpaceEditor label="Emailadresse bestätigen" rawState={mailEmailConfirmationText} onChangeExportRawState={(state: any) => {setMailEmailConfirmationText(JSON.stringify(state))}} />

                    <EvjuSpaceEditor label="Passwortvergessenmail" rawState={mailForgotPasswordText} onChangeExportRawState={(state: any) => {setMailForgotPasswordText(JSON.stringify(state))}} />
                </IndexCard>
                

                <IndexCard headerBox="Nutzerverwaltung">
                    <p>ToDo</p>
                </IndexCard>

                <IndexCard headerBox="Rechtstexte">
                    <h3>Impressum</h3>
                        <EvjuSpaceEditor rawState={impressum} onChangeExportRawState={(state: any) => {setImpressum(JSON.stringify(state))}} />
                        <EvjuSpaceButton text="Impressum aktualisieren" onClick={saveImpressum} />
                    <h3>Nutzungsbedingungen</h3>
                        <EvjuSpaceEditor rawState={termsOfUse} onChangeExportRawState={(state: any) => {setTermsOfUse(JSON.stringify(state))}} />
                        <EvjuSpaceButton text="Nutzungsbedingungen aktualisieren" onClick={saveTermsOfUse} />
                    <h3>Datenschutz</h3>
                        <EvjuSpaceEditor rawState={privacy} onChangeExportRawState={(state: any) => {setPrivacy(JSON.stringify(state))}} />
                        <EvjuSpaceButton text="Datenschutzerklärung aktualisieren" onClick={savePrivacy} />
                    <hr />
                    <EvjuSpaceButton text="Forced Logout" onClick={() => {setIsForcedLogoutOverlayOpen(true)}} />
                </IndexCard>
            </Card>

            <EvjuSpaceOverlay isOpen={isForcedLogoutOverlayOpen} onClose={() => {setIsForcedLogoutOverlayOpen(false)}}>
                <p>Wenn du das bestätigst, werden alle User zwangsweise ausgeloggt und haben nicht die Möglichkeit Dinge zu speichern o.Ä.! Mach das nur, wenn du dir sicher bist!</p>
                <EvjuSpaceButton text="Ja, ich bin mir sicher." onClick={forcedLogout} />
            </EvjuSpaceOverlay>
        </>
    );
}
export default Adminpanel;

export async function adminpanelLoader({ params } : any) {

    const emailSettingsArray = await getEmailSettings()

    let emailSettings: any = {}

    emailSettingsArray.map((setting, i) => {
        switch(setting.name) {
            case "smtpServer":
                emailSettings.smtpServer = setting.value
                break;
            case "smtpPort":
                emailSettings.smtpPort = setting.value
                break;
            case "smtpUser":
                emailSettings.smtpUser = setting.value
                break;
            case "smtpPassword":
                emailSettings.smtpPassword = setting.value
                break;
            case "fromAdress":
                emailSettings.fromAdress = setting.value
                break;
            case "greeting":
                emailSettings.greeting = setting.value
                break;
            case "farewells":
                emailSettings.farewells = setting.value
                break;
            case "welcomeText":
                emailSettings.welcomeText = setting.value
                break;
            case "emailConfirmationText":
                emailSettings.emailConfirmationText = setting.value
                break;
            case "forgotPasswordText":
                emailSettings.forgotPasswordText = setting.value
                break;
        }
    })

    const legalDocs = {
        impressum: await getImpressum(),
        termsOfUse: await getTermsOfUse(),
        privacy: await getPrivacy(),
    }

    return [ emailSettings, legalDocs ];
}