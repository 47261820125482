import { useState } from "react";
import { newServer } from "../../../connectors/ServerConnector";
import Card from "../../Components/Card";
import EvjuSpaceUserSelector from "../../Components/FormElements/EvjuSpaceUserSelector";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../../Components/FormElements/EvjuSpaceButton";

function NewServer() {

    const [serverName, setServerName] = useState<string>("")
    const [admin, setAdmin] = useState<Array<string>>()

    async function btnHandler() {
        if(admin && admin.length > 0) {
            const response = await newServer(serverName, admin[0])
            console.log(response)
            if(response.serverid) {
                window.location.href = '/server/' + response.serverid
            }
        }
    }

    return (
        <>
            <Card heading="Einstellungen" headerBox="NewServer" back="/settings/admin">
                <EvjuSpaceInput label="Servername" value={serverName} onChange={setServerName} />
                <EvjuSpaceUserSelector label="Serveradmin" value={admin} onChange={setAdmin} />
                <EvjuSpaceButton text="Server erstellen" onClick={btnHandler} />
            </Card>
        </>
    );
}
export default NewServer;