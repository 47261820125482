import { useLoaderData } from "react-router-dom";
import { EvJuSpaceLegalDoc, getImpressum } from "../../connectors/LegalConnector";
import Card from "../Components/Card";
import draftToHtml from "draftjs-to-html";

function Footer() {

    return (
        <footer className="footer">
            <p>
                <a href="/impressum">Impressum</a>
                <a href="/privacy">Datenschutzerklärung</a>
                <a href="/termsofuse">Nutzungsbedingungen</a>
            </p>
        </footer>
    );
}
export default Footer;