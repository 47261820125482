import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import StarAnimation from "../StarAnimation";
import EvjuSpaceInput from "../../Layout/Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../../Layout/Components/FormElements/EvjuSpaceButton";
import { getSetting } from "../../connectors/SettingsConnector";
import Footer from "../../Layout/LegalDoc/Footer";

function Register() {

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<String>("");
    const [username, setUsername] = useState<String>("");
    const [password, setPassword] = useState<String>("");
    const [firstName, setFirstName] = useState<String>("");
    const [lastName, setLastName] = useState<String>("");
    const [smartphone, setSmartphone] = useState<String>("");
    const [userContext, setUserContext] = useContext<any>(UserContext);

  const verifyUser = useCallback(() => {
    console.log(process.env)
    fetch(process.env.REACT_APP_USER_SERVICE + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: data.token }
        })
      } else {
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: null }
        })
      }
      // call refreshToken every 5 minutes to renew the authentication token.
      setTimeout(verifyUser, 5 * 60 * 1000)
    })
  }, [setUserContext])

  useEffect(() => {
    const fetchData = async () => {
      if((await getSetting('registrationEnabled')) == 'false') {
        window.location.replace("/login");
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

    useEffect(() => {
        if(error != "") {
          console.error(error)
        }
      }, [error])
      
    function formSubmitHandler (e : any) {
        e.preventDefault()
        setIsSubmitting(true)
        setError("")
    
        const genericErrorMessage = "Something went wrong! Please try again later."
    
        fetch(process.env.REACT_APP_USER_SERVICE + "users/signup", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ firstName, lastName, smartphone, username, password }),
        })
          .then(async response => {
            setIsSubmitting(false)
            if (!response.ok) {
              if (response.status === 400) {
                setError("Bitte füll alle Felder aus.")
              } else if (response.status === 401) {
                setError("Ein unbekannter Fehler ist aufgetreten.")
              } else if (response.status === 500) {
                const data = await response.json()
                if (data.message) setError(data.message || genericErrorMessage)
              } else {
                setError(genericErrorMessage)
              }
            } else {
              const data = await response.json()
              setUserContext((oldValues : any) => {
                return { ...oldValues, token: data.token }
              })
            }
          })
          .catch(error => {
            setIsSubmitting(false)
            setError(genericErrorMessage)
          })
    }

    if(userContext.token) {
      window.location.replace("/");
    }
  
    return (
      <>
        <div className="login-container">
          <h1>Registrieren</h1>
          {error && <div className="login-error">Fehler: {error}</div>}
            <form onSubmit={formSubmitHandler}>
              <EvjuSpaceInput onChange={setUsername} value={username} label="E-Mail" />
              <EvjuSpaceInput type="password" onChange={setPassword} value={password} label="Passwort" />
              <EvjuSpaceInput onChange={setFirstName} value={firstName} label="Vorname" />
              <EvjuSpaceInput onChange={setLastName} value={lastName} label="Nachname" />
              <EvjuSpaceInput onChange={setSmartphone} value={smartphone} label="Smartphone" />
              <EvjuSpaceButton class="login-button" text="Registrieren" onClick={() => {}} />
            </form>

            <p><Link to={"/login"}>Du hast schon einen Account? - Login</Link></p>

          </div>
          <Footer />
      </>
    );
}

export default Register;