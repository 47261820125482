import { useEffect, useState } from "react";
import EvjuSpaceEditor from "../../Components/FormElements/EvjuSpaceEditor";
import { updateServerDescription } from "../../../connectors/ServerConnector";
import { servicesVersion } from "typescript";


function EditServerSettings(props:any) {

    const [serverDescription, setServerDescription] = useState<string>(props.server.description? props.server.description : "")

    useEffect(() => {
        updateServerDescription(props.server._id, serverDescription)
    }, [serverDescription])

    return <>
        <EvjuSpaceEditor label="Serverbeschreibung" rawState={serverDescription} onChangeExportRawState={(state: any) => {setServerDescription(JSON.stringify(state))}} />
    </>
}
export default EditServerSettings;