import "./../../style.css";
import 'remixicon/fonts/remixicon.css'

export interface EvjuSpaceButtonParameter {
    class?: string,
    onClick?: any,
    text?: string,
    error?: boolean,
}

function EvjuSpaceButton(props : EvjuSpaceButtonParameter) {

    function click() {
        if(props.onClick) {
            props.onClick();
        } else {
            console.error("onClick is undefined")
        }
    }

    let cssClass = ""
    if(props.class) {
        cssClass = props.class
    }

    return (
        <button className={"evjuspaceinput evjuspaceinput-button " + (props.error ? " evjuspaceinput-error" : "") + cssClass} onClick={click} disabled={props.error}>{props.text}</button>
    );
}
export default EvjuSpaceButton;