import Card from "../Components/Card";
import EvjuSpaceOverlay from "../Components/Overlay";
import { useContext, useState } from "react";
import EvjuSpaceInput from "../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceEditor from "../Components/FormElements/EvjuSpaceEditor";
import EvjuSpaceButton from "../Components/FormElements/EvjuSpaceButton";
import { createContact, EvJuSpaceContact, getBugreportList } from "../../connectors/ContactConnector";
import { useLoaderData } from "react-router-dom";
import IndexCard from "../Components/IndexCard";
import draftToHtml from "draftjs-to-html";
import { getUser } from "../../connectors/UserConnector";
import { UserContext } from "../../context/UserContext";

function Bugreport() {
    let reportedBugs : any = useLoaderData();

    const [userContext, setUserContext] = useContext<any>(UserContext);
    
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("");
    const [hasDescriptionText, setHasDescriptionText] = useState<boolean>(false)


    function openOverlay() {
        setIsOverlayOpen(true)
    }

    async function createBugreport() {
        setIsOverlayOpen(false)
        await createContact({
            title: title,
            description: description,
        })

        reportedBugs.push({
            title: title,
            description: description,
            state: "new",
            author: userContext.details?.firstName + " " + userContext.details?.lastName
        })

        setTitle("")
        setDescription("")
    }

    return (
        <>
            <Card heading="Bugreport" addFn={openOverlay}>
                <p>Wenn dir Dinge auffallen, die in evju.space einfach nicht funktionieren, oder dir ganz neue Funktionen einfallen, die du gerne hättest, füg gerne über das + in der oberen rechten Ecke einen neuen Report hinzu und schreib da möglichst genau rein, worum es geht.</p>
                {reportedBugs.length > 0 ? reportedBugs.map((bugreport: EvJuSpaceContact, i: number) => {
                    return <IndexCard headerBox={bugreport.title} key={"bugreport" + i}>
                        Autor: {bugreport.author} <br />
                        Status: {bugreport.state} <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(bugreport.description))}} ></div>

                    </IndexCard>
                }) : "Gerade liegen keine offenen Bugreports vor. Erstelle über das + oben rechts in der Ecke einen neuen Bugreport."}
            </Card>

            <EvjuSpaceOverlay isOpen={isOverlayOpen} onClose={() => {setIsOverlayOpen(false)}}>
                <h2>Erstelle einen neuen Bugreport!</h2>
                <EvjuSpaceInput label="Titel" value={title} onChange={setTitle} error={title === ""} />
                <EvjuSpaceEditor label="Ausführliche Beschreibung" rawState={description} onChangeExportRawState={(state: any) => {setDescription(JSON.stringify(state))}}  hasText={setHasDescriptionText} error={!hasDescriptionText}/>
                <EvjuSpaceButton text="Absenden" onClick={createBugreport} error={title === "" || !hasDescriptionText} />
            </EvjuSpaceOverlay>
        </>
    );
}
export default Bugreport;

export async function bugreportLoader({ params } : any) {
    let bugreports = await getBugreportList();
    bugreports.forEach(async (bugreport) => {
        if(bugreport.authorId) {
            const author = await getUser(bugreport.authorId)
            bugreport.author = author.firstName + " " + author.lastName
        }
    })
    return bugreports;
}