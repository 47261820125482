import { useLoaderData } from "react-router-dom";
import { EvJuSpaceLegalDoc, getImpressum, getPrivacy } from "../../connectors/LegalConnector";
import Card from "../Components/Card";
import draftToHtml from "draftjs-to-html";
import Footer from "./Footer";

function Privacy() {
    
    const doc: any = useLoaderData();

    return (
        <>
            <Card heading={"Datenschutzerklärung"} back="javascript:history.back()" visible_when_loggedout={true} >    
                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(doc.text))}} ></div>
            </Card>
            <Footer />
        </>
    );
}
export default Privacy;

export async function privacyLoader({ params } : any) {
    const doc:EvJuSpaceLegalDoc = await getPrivacy()
    return doc;
}