import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "./Widget";
import { EvjuSpaceAgendaItem, EvjuSpaceEvent, EvjuSpaceEventSlim, getEvents } from "../../../connectors/EventConnector";

function AgendaWidget(props : any) {
    const event: EvjuSpaceEvent = props.event

    return (
        <Widget headerBox="Tagesordnung" edit={props.editable ? "/group/" + event.group + "/event/" + event._id + "/agenda/" : ""} >
            {event.agenda != null ? event.agenda?.map((agendaItem: EvjuSpaceAgendaItem, i:number) => {
                return (
                    <div className="agendaitem-container" key={"agendaitemContainer-" + agendaItem.name}>
                        <div className="agendaitem">
                            <div className="agendaitem-text">
                                <span className="agendaitem-number">{ (i+1) + "." }</span>
                                {agendaItem.name}
                            </div>
                        </div>
                        {agendaItem.subAgenda ? agendaItem.subAgenda.map((subAgendaItem, j) => {
                            return (
                                <div className="subagendaitem" key={"agendaitem-" + agendaItem.name + "-subagendaitem-" +subAgendaItem.name}>
                                    <div className="agendaitem-text">
                                        <span className="subagendaitem-number">{ (i+1) + "." + (j+1) + "." }</span>
                                        {subAgendaItem.name}
                                    </div>
                                </div>
                            );
                        }) : ""}
                    </div>
                );
            }) : ""}
        </Widget>
    );

}
export default AgendaWidget;