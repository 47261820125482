export interface EvJuSpaceLegalDoc {
    _id?: string,
    title: string,
    text: string,
    version?: string,
}

export async function saveLegalDoc(legalDoc: EvJuSpaceLegalDoc) {
    const response = await fetch(process.env.REACT_APP_LEGAL_SERVICE + "legal/doc/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            title: legalDoc.title,
            text: legalDoc.text,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function getImpressum():Promise<EvJuSpaceLegalDoc> {
    const response = await fetch(process.env.REACT_APP_LEGAL_SERVICE + "legal/doc/Impressum", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function getTermsOfUse():Promise<EvJuSpaceLegalDoc> {
    const response = await fetch(process.env.REACT_APP_LEGAL_SERVICE + "legal/doc/TermsOfUse", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function getPrivacy():Promise<EvJuSpaceLegalDoc> {
    const response = await fetch(process.env.REACT_APP_LEGAL_SERVICE + "legal/doc/Privacy", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}
