export interface EvJuSpaceContact {
    _id?: string,
    title: string,
    description: string,
    state?: string,
    authorId?: string,
    author?: string,
    answer?: string,
}

export async function createContact(contact: EvJuSpaceContact) {
    const response = await fetch(process.env.REACT_APP_CONTACT_SERVICE + "contact/contact/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            title: contact.title,
            description: contact.description,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function getBugreportList():Promise<Array<EvJuSpaceContact>> {
    let data:Array<EvJuSpaceContact> = []

    const response = await fetch(process.env.REACT_APP_CONTACT_SERVICE + "contact/contacts/", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
}
